module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.25._wsgcpxbha4rw6gfd7eo7cvbcbu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_deq3uwq4hqo4wdjjssz6e6yp74/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hotelston","short_name":"Hotelston","background_color":"#176529","theme_color":"#176529","display":"standalone","legacy":true,"icon":"src/images/favicon.png","icons":[{"src":"icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icon-512x512.png","sizes":"144x144","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"088ca9a939811d14ea1e27bd48123da3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__j3nwtvwgkwanxqfskc63nnmlhm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
