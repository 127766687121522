import { configureStore } from "@reduxjs/toolkit"
import bodyDimensionsReducer from "./reducer/bodyDimensions.reducer"
import hamburgerMenuReducer from "./reducer/hamburgerMenu.reducer"
import imageSliderReducer from "./reducer/imageSlider.reducer"
import userReducer from "./reducer/user.reducer"
import dynamicNavMenuReducer from "./reducer/dynamicNavMenu.reducer"

export const createReduxStore = () => configureStore({
  reducer: {
    bodyDimensions: bodyDimensionsReducer,
    dynamicNavMenu: dynamicNavMenuReducer,
    hamburgerMenu: hamburgerMenuReducer,
    imageSlider: imageSliderReducer,
    user: userReducer
  }
})

export default createReduxStore;